import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from 'primereact/multiselect';
import fetchMethodRequest from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

const KanbanBoard = (props) => {

  let taskOptions = [
    "new",
    "in development",
    "dev completed",
    "review completed",
    "ready for testing",
    "analysis/design",
    "need clarification",
    "reopen",
    "resolved",
    "moved to prod",
    "onhold",
    "future implementation",
    "unable to replicate",
    "duplicate",
    "not an issue"
  ];


  const uniqueAssignedTo = props?.taskDetails
    .map(task => task.assignedTo)
    .filter((value, index, self) =>
      self.findIndex(v => v?._id === value?._id) === index
    );

  const updatedData = uniqueAssignedTo.map(item => {
    const [firstName, lastName] = item?.displayName.split(" ");
    const short = `${firstName[0]}${lastName[0]}`.toUpperCase(); // for first letter of both names in uppercase
    return { ...item, short };
  });

  const [selectEmployee, setSelectEmployee] = useState(updatedData);
  const [selectEmployeeResponse, setSelectEmployeeResponse] = useState(null);

  let taskDetails;
  if (selectEmployeeResponse !== null) {
    taskDetails = Array.isArray(selectEmployeeResponse) ? selectEmployeeResponse : [];
  } else {
    taskDetails = Array.isArray(props?.taskDetails) ? props.taskDetails : [];
  }

  // kanbanData grouping by task status
  const kanbanData = taskDetails.reduce((acc, task) => {
    const status = task.taskStatus || "No Status";
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(task);
    return acc;
  }, {});

  // const columnOrder = Object.keys(kanbanData).filter((key) => Array.isArray(kanbanData[key]));
  const [columns, setColumns] = React.useState(taskOptions);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // if dropped outside of any droppable area, return
    if (!destination) return;

    // if dragged to the same position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering within the same column
    if (source.droppableId === destination.droppableId) {
      const updatedKanbanData = { ...kanbanData };
      const column = columns[source.droppableId];
      const tasks = updatedKanbanData[column] || [];
      const [movedTask] = tasks.splice(source.index, 1);
      tasks.splice(destination.index, 0, movedTask);

      const payload = {
        tasks: []
      };
      tasks.forEach((task, index) => {
        task.kanbanPriority = index;
        payload.tasks.push({
          taskId: task._id,
          kanbanPriority: index
        });
      });

      const url = `tasks/multiupdateOfPriority`;
      fetchMethodRequest("POST", url, payload)
        .then((response) => {
          if (response?.respCode) {
            showToasterMessage(response.respMessage, "success");
            props.setActivity()
          } else if (response?.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        });

      updatedKanbanData[column] = tasks;
    } else {
      // Handle moving task between different columns
      const updatedKanbanData = { ...kanbanData };
      const sourceColumn = columns[source.droppableId];
      const destinationColumn = columns[destination.droppableId];

      const sourceTasks = updatedKanbanData[sourceColumn] || [];
      const destinationTasks = updatedKanbanData[destinationColumn] || [];

      const [movedTask] = sourceTasks.splice(source.index, 1); // Removing the task from the source column
      destinationTasks.splice(destination.index, 0, movedTask); // Add the task to the destination column
      movedTask.taskStatus = destinationColumn; // Update taskStatus to reflect the destination column

      let payload = {
        taskStatus: destinationColumn
      }
      const url = `tasks/${movedTask._id}`;
      fetchMethodRequest("PUT", url, payload)
        .then((response) => {
          if (response?.respCode) {
            showToasterMessage(response.respMessage, "success");
            props.setActivity()
          } else if (response?.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        });

      updatedKanbanData[sourceColumn] = sourceTasks;
      updatedKanbanData[destinationColumn] = destinationTasks;
    }
  };


  const selectingEmployee = (event) => {
    setSelectEmployee(event.value)
    let filterCriteria = {
      limit: 50,
      page: 1,
      criteria: [
        { key: "sprint", value: props.sprintId, type: "in" },
        { key: "assignedTo", value: event.value, type: "in" }
      ],
      sortfield: 'created',
      direction: 'desc'
    }

    let url = `tasks?filter=${JSON.stringify(filterCriteria)}`
    fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response) {
          setSelectEmployeeResponse(response.tasks)
        }
      }).catch((err) => {
        return err;
      });
  };

  const countryTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span className="kanban-assignees">
          {option?.short || "EM"}
        </span>
        <div>{option?.displayName}</div>
      </div>
    );
  };

  // const generateRandomColor = () => {
  //   const letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  return (
    <div>
      <div className="dropdown-container">
        <MultiSelect style={{ height: '2.5rem', width: '50%', border: 'none', boxShadow: 'none', borderColor: 'transparent' }} value={selectEmployee} options={updatedData} onChange={(e) => selectingEmployee(e)} optionLabel="short"
          placeholder="Select Employee(s)" itemTemplate={countryTemplate} className="w-full md:w-20rem" display="chip" />
      </div>

      <div>
        <div className="kanban-board">
          <DragDropContext onDragEnd={onDragEnd}>
            {columns.length > 0 ? (
              columns.map((status, index) => {
                const allTasks = Array.isArray(kanbanData[status]) ? kanbanData[status] : [];
                const tasks =   allTasks.sort((a, b) => {
                  const aPriority = a.kanbanPriority ?? 0;
                  const bPriority = b.kanbanPriority ?? 0;
                  return aPriority - bPriority; 
                });
                const assignedTaskCounts = tasks.reduce((acc, task) => {
                  const assignee = task?.assignedTo?.displayName || "Unassigned";
                  acc[assignee] = (acc[assignee] || 0) + 1;
                  return acc;
                }, {});

                return (
                  <Droppable key={status} droppableId={index.toString()}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="kanban-column"
                      >
                        <div className="kanban-column-header">
                          {status === "new" && <FontAwesomeIcon icon='list' style={{ color: '#326fd1' }} />}
                          {status === "in development" && <FontAwesomeIcon icon='spinner' style={{ color: '#9fa9b7' }} />}
                          {status === "dev completed" && <FontAwesomeIcon icon='check' style={{ color: '#198754' }} />}
                          {status === "analysis/design" && <FontAwesomeIcon icon='search' style={{ color: '#00FF00' }} />}
                          {status === "review completed" && <FontAwesomeIcon icon='eye' style={{ color: '#6366f1' }} />}
                          {status === "ready for testing" && <FontAwesomeIcon icon='briefcase' style={{ color: '#eec137' }} />}
                          {status === "need clarification" && <FontAwesomeIcon icon='play' style={{ color: '#FF00FF' }} />}
                          {status === "reopen" && <FontAwesomeIcon icon='undo' style={{ color: 'red' }} />}
                          {status === "resolved" && <FontAwesomeIcon icon='thumbs-up' style={{ color: '#0dcaf0' }} />}
                          {status === "moved to prod" && <FontAwesomeIcon icon='cloud' style={{ color: '#00FFFF' }} />}
                          {status === "onhold" && <FontAwesomeIcon icon='ban' style={{ color: '#000000' }} />}
                          {status === "future implementation" && <FontAwesomeIcon icon='bookmark' style={{ color: '#000080' }} />}
                          {status === "unable to replicate" && <FontAwesomeIcon icon='exclamation-triangle' style={{ color: '#A52A2A' }} />}
                          {status === "duplicate" && <FontAwesomeIcon icon='clone' style={{ color: '#808080' }} />}
                          {status === "not an issue" && <FontAwesomeIcon icon='flag' style={{ color: '#FFA500' }} />}
                          &nbsp;&nbsp;
                          {status.toUpperCase()} ({tasks.length})
                        </div>
                        <div className="kanban-column-content mb-3">
                          {tasks.length > 0 ? (tasks.map((task, idx) => (
                            <Draggable
                              key={task._id}
                              draggableId={task._id}
                              index={idx}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`kanban-card ${status}`}
                                >
                                  <div className="kanban-card-header">
                                    <span
                                      className="kanban-task-id"
                                      onClick={() => {
                                        props.setOpenTaskDetailsDialog?.(true);
                                        props.setVisibleTaskDetails?.(true);
                                        props.setNeededTaskID?.(task);
                                      }}
                                    >
                                      {task.taskID}
                                    </span>
                                    <span
                                      className="kanban-assignee"
                                      title={task?.assignedTo?.displayName}
                                    >
                                      {task?.assignedTo?.displayName?.[0] || 'E'}
                                    </span>
                                  </div>
                                  <div
                                    className="kanban-card-title"
                                    title={task.name}
                                  >
                                    {task.name || 'No Title'}
                                  </div>
                                  <div className="kanban-card-details">
                                    {task.estimatedHours && (
                                      <div className="kanban-detail">
                                        Estimated Hours: {task.estimatedHours}
                                      </div>
                                    )}
                                    {task.actualWorkingHours && (
                                      <div className="kanban-detail">
                                        Actual Working Hours:{' '}
                                        {task.actualWorkingHours}
                                      </div>
                                    )}
                                    {task.taskCompletionPercent !==
                                      undefined && (
                                        <div className="kanban-detail">
                                          Task Completion:{' '}
                                          {task.taskCompletionPercent}%
                                        </div>
                                      )}
                                  </div>
                                  <div className="kanban-card-footer mt-1">
                                    {task.endDate && (
                                      <span className="tag tag-improvement">
                                        End-Date: {task.endDate}
                                      </span>
                                    )}
                                  </div>
                                  <div className="kanban-card-footer-end mt-1">
                                    <span className="tag tag-assignName">
                                      {task?.assignedTo?.displayName || "Unassigned"}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))) : (
                            <div className="kanban-card no-tasks">
                              No tasks for this status
                            </div>
                          )}
                        </div>
                        <div className="kanban-column-footer">
                          {Object.entries(assignedTaskCounts).length > 0 ? (
                            Object.entries(assignedTaskCounts).map(
                              ([assignee, count]) => (
                                <div key={assignee} className="kanban-assignee-summary">
                                  {assignee}: {count} task(s)
                                </div>
                              )
                            )
                          ) : (
                            <div className="kanban-assignee-summary">
                              No Assignees
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                );
              })
            ) : (
              <div className="kanban-column no-tasks">No Tasks Found</div>
            )}
          </DragDropContext>
        </div>

        <div className="kanban-total-tasks">
          Total Task(s): {taskDetails.length}
        </div>
      </div>
    </div>
  );
};

export default KanbanBoard;
