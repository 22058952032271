import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import fetchMethodRequest from '../../../../config/service';
import { useParams } from "react-router";
import dateFormats from '../../../UI/FormatDate/formatDate';
import config from '../../../../config/config';
import { Link } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton';
import ShowMeetingNotes from "../../CommonModals/ShowMeetingNotes";

const Dashboards = (props) => {
  const [birthDays, setBirthDays] = useState([]);
  const [taskData, setTaskData] = useState();
  const [leaves, setLeaves] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState('');
  const [timeSheetDetails, setTimeSheetDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  let [serialNumber, setSerialNumber] = useState(1);

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const params = useParams();

  useEffect(() => {
    getCountData();
    getLeavesData();
    getMeetingNotes();
  }, []);

  const getCountData = () => {
    setIsLoading(true);
    fetchMethodRequest('GET', `dashboards`)
      .then(async (response) => {
        if (response) {
          setLeaveBalance(response.leaveBalance)
          if (response && response.birthDay && response.birthDay.length > 0) {
            setBirthDays(response.birthDay);
          }
          if (response && response.totalTasks) {
            setTaskData(response.totalTasks)
          }
          if (response && response.unFilledTimesheets) {
            setTimeSheetDetails(response.unFilledTimesheets)
          }
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getLeavesData = () => {
    setIsLoading(true);
    let url;
    let today = new Date();
    const inputDate = dateFormats.formatDateForFunnelFilter(today, config.dayYearDateFormat);
    let filterObj = {
      'sortfield': 'fromDate', 'direction': 'desc',
      "criteria": [{ 'key': 'toDate', 'value': inputDate, 'type': 'gte' }]
    };
    url = 'leaves?type=DashBoard' + '&filter=' + JSON.stringify(filterObj) + '';
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response && response.leaves && response.leaves.length > 0) {
          response.leaves.forEach((leave) => {
            leave.fromDate = dateFormats.formatDate(leave.fromDate, config.dateDayMonthFormat);
          })
          setLeaves(response.leaves)
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getMeetingNotes = () => {
    setIsLoading(true);
    let url;
    let filterObj = { "limit": 50, "page": 1, "criteria": [{ "key": "status", "value": ["Active", "Pending"], "type": "in" }], "sortfield": "created", "direction": "desc" }
    url = 'projects?filter=' + JSON.stringify(filterObj);
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response && response.projects) {
          setMeetingNotes(response.projects)
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }

  const openNotes = (item) => {
    setSelectedItem(item);
    setShowDialog(true);
  };

  const handleCloseNotes = () => {
    setShowDialog(false);
    setSelectedItem(null);
  };

  const isUpdateEditorValues = (values) => {
    values = values.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
  }

  let maxLength = timeSheetDetails ? Math.max(...Object.values(timeSheetDetails).map(arr => arr.length)) : null;

  return (
    <div className="dashboard">
      <Row style={{ margin: "5px" }}>
        <Col md={12}>
          <h3 className="page-title" style={{ marginBottom: '15px' }}><b style={{ fontSize: '20px' }}>{'Dashboard'}</b></h3>
        </Col>
      </Row>

      <Row>
        {(birthDays && birthDays.length > 0) ?
          <Col xs={12} md={4} lg={6} xl={4}>
            <div className="row birthDayBackground mb-3 mx-1">
              <div className='col-6'>
                {birthDays.map((item, index) => {
                  return (
                    <div key={index} style={{ margin: "10px" }}>
                      <span style={{ color: '#f17038' }}>{'Happy Birthday :)'}</span><br />
                      <span style={{ color: 'blue' }}>{item.firstName}</span>
                    </div>
                  )
                })}
              </div>
              <div className='col-6'>
                <img src="https://i0.wp.com/media3.giphy.com/media/sIoUUXfh3W51K/giphy.gif" alt="" />
              </div>
            </div>
          </Col>
          : null}


        {leaveBalance || leaveBalance === 0 ?
          <Col xs={12} md={4} lg={6} xl={2}>
            <Card>
              <CardBody className="dashboard__card-widget-small text-center">
                <div className="card__title">
                  <h6 className="bold-text">{'LEAVE BALANCE'}</h6>
                </div>
                <Link to="/leaves" style={{ textDecoration: 'none' }}>
                  <div className="dashboard__total">
                    <p className="dashboard__total-stat">
                      <span style={{ color: "#007ad9" }}>{leaveBalance}</span>
                    </p>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          : null}
      </Row>

      <Row>
        <Col lg={6} xl={6}>
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{'MISSING TIMESHEETS'}</h5>
              </div>
              <Table responsive hover className="table--head-accent table-fixed">
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 37 + '%' }}>{'Name'}</th>
                      <th style={{ width: 55 + '%' }}>{'Date'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {loginRole && loginRole.roleType && (loginRole.roleType === 'Manager' || loginRole.roleType === 'Admin') ? (
                      timeSheetDetails && Object.keys(timeSheetDetails).length > 0 ? (
                        Object.keys(timeSheetDetails).map((key, i) => {
                          const missingDates = timeSheetDetails[key]?.missingTimeSheetDates || [];
                          const empobj = {
                            displayName: timeSheetDetails[key]?.employeeDetails?.displayName,
                            _id: timeSheetDetails[key]?.employeeDetails?._id
                          }
                          const row = (
                            <tr key={key}>
                              <td style={{ width: '8%' }}>{i + 1}</td>
                              <td style={{ width: '37%', textTransform: "capitalize" }}>{key}</td>
                              <td style={{ width: '55%', color: '#007ad9' }}>
                                {missingDates.map((day, index) => (
                                  <span key={index} style={{ color: "#f17038" }}>
                                    <Link to={`/timeSheets/date/${encodeURIComponent(day)}`} state={{ empobj }} style={{ textDecoration: 'none' }}>
                                      {new Date(day).getDate()}
                                    </Link>
                                    {index < missingDates.length - 1 && ', '}
                                  </span>
                                ))}
                              </td>
                            </tr>
                          );
                          return row;
                        })
                      ) : (
                        <tr className="bold-text">
                          <td style={{ width: '100%', textAlign: 'center', paddingTop: '95px' }} colSpan="3">
                            {'No Timesheet Updates'}
                          </td>
                        </tr>
                      )
                    ) : (
                      timeSheetDetails && Object.keys(timeSheetDetails).length > 0 ? (
                        Object.keys(timeSheetDetails).map((key, i) => {
                          const missingTimeSheetDates = timeSheetDetails[key]?.missingTimeSheetDates || [];
                          return missingTimeSheetDates.map((date, j) => {
                            const serialNumber = i * missingTimeSheetDates.length + j + 1;
                            const row = (
                              <tr key={`${i}-${j}`}>
                                <td style={{ width: '8%' }}>{serialNumber}</td>
                                <td style={{ width: '37%', textTransform: "capitalize" }}>
                                  {key}
                                </td>
                                <td style={{ width: '55%', color: '#007ad9' }}>
                                  <Link to={`/timeSheets/date/${encodeURIComponent(date)}`} style={{ textDecoration: 'none' }}>
                                    {date}
                                  </Link>
                                </td>
                              </tr>
                            );
                            return row;
                          });
                        }).flat()
                      ) : (
                        <tr className="bold-text">
                          <td style={{ width: '100%', textAlign: 'center', paddingTop: '95px' }} colSpan="3">
                            {'No Timesheet Updates'}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                )}
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{'RECENT LEAVES'}</h5>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 40 + '%' }}>{'Employee'}</th>
                      <th style={{ width: 30 + '%' }}>{'From Date'}</th>
                      <th style={{ width: 22 + '%' }}>{'No Of Days'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {leaves && leaves.length > 0 ?
                      leaves.map((leave, i) => {
                        const isMatch = leave.employeeName?._id === loginRole?._id;
                        return <tr key={i} style={{ backgroundColor: isMatch ? '#fbff2b' : 'transparent' }}>
                          <td style={{ width: 8 + '%' }}>{i + 1} </td>
                          <td style={{ width: 40 + '%', textTransform: "capitalize" }}>{leave.employeeName?.displayName}</td>
                          <td style={{ width: 30 + '%', paddingLeft: '15px' }}>{leave.fromDate}</td>
                          <td style={{ width: 22 + '%', paddingLeft: '50px' }}>{leave.numberOfDays}</td>
                        </tr>
                      })
                      :
                      <tr className="bold-text">
                        <td style={{ width: 100 + '%', textAlign: 'center', paddingTop: '95px' }} colSpan="4">
                          {'No Recent Leaves'}
                        </td>
                      </tr>
                    }
                  </tbody>)}
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{'ACTIVE TASKS'}</h5>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 5 + '%' }}>#</th>
                      <th style={{ width: 12 + '%' }}>{'Task ID'}</th>
                      <th style={{ width: 43 + '%' }}>{'Task Name'}</th>
                      <th style={{ width: 20 + '%' }}>{'Sprint'}</th>
                      <th style={{ width: 20 + '%' }}>{'Project'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {taskData && taskData.map((item, i) => {
                      const sprintNames = item && item.sprintName && item.sprintName.join(', ');
                      return <tr key={i}>
                        <td style={{ width: 5 + '%' }}>{i + 1} </td>
                        <td style={{ width: 12 + '%', textTransform: "capitalize" }}>{item.taskID}</td>
                        <td style={{ width: 43 + '%', textTransform: "capitalize" }}>{item.name}</td>
                        <td style={{ width: 20 + '%', textTransform: "capitalize" }}>{sprintNames}</td>
                        <td style={{ width: 20 + '%', textTransform: "capitalize" }}>{item.projectName}</td>
                      </tr>
                    })}
                  </tbody>)}
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{'Meeting Notes'}</h5>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 22 + '%' }}>{'Project'}</th>
                      <th style={{ width: 30 + '%' }}>{'Meeting Notes'}</th>
                      <th style={{ width: 20 + '%' }}>{'Date'}</th>
                      <th style={{ width: 20 + '%' }}>{'Created By'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {meetingNotes && meetingNotes.map((project, projectIndex) => {
                      project.meetingNotes.sort((a, b) => {
                        const dateA = new Date(a.meetingDate.split("-").reverse().join("-"));
                        const dateB = new Date(b.meetingDate.split("-").reverse().join("-"));
                        return dateB - dateA;
                      });

                      return project.meetingNotes.map((item, i) => (
                        (item.status === "In Discussion" || item.status === "") &&
                        <tr key={`${projectIndex}-${i}`}>
                          <td style={{ width: 8 + '%' }}>{serialNumber++}</td>
                          <td style={{ width: 22 + '%', textTransform: 'capitalize', color: '#f17038' }} onClick={() => openNotes(project)}>{item.projectName}</td>
                          <td style={{ width: 30 + '%', textTransform: 'capitalize', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={isUpdateEditorValues(item.projectNotes)}>{isUpdateEditorValues(item.projectNotes)}</td>
                          <td style={{ width: 20 + '%', textTransform: 'capitalize' }}>{item.meetingDate}</td>
                          <td style={{ width: 20 + '%', textTransform: 'capitalize' }}>{item.createdByName}</td>
                        </tr>
                      ));
                    })}
                  </tbody>)}
              </Table >
            </CardBody >
          </Card >
        </Col >
      </Row >
      {/* Notes Modal */}
      {
        showDialog && (
          <ShowMeetingNotes
            item={selectedItem}
            openViewNotes={showDialog}
            closeViewNotes={handleCloseNotes}
          />
        )
      }
    </div >
  );
};
export default Dashboards;
